import React, { FunctionComponent, ReactNode, useCallback, useEffect, useState } from 'react'
import moment from 'moment'
import Moment from 'react-moment'
import { useDispatch } from 'react-redux'
import { getHostAccountDetails } from '../../api/host'
import { updateAccountDetails } from '../../redux/hosts'
import { useTypedSelector } from '../../redux/reducers'
import { isDemo } from '../../redux/selectors'
import AuthRole from '../../role'
import ApplyLicenseModal, { checkApplyLicense } from '../Account/ApplyLicenseModal'
import { AccountDataUtil, AccountDetails, NOT_AVAILABLE } from '../Account/Data'
import { EditCustomerFunction, HostData, HostDataUtil, SystemInfoData } from '../Host/Data'
import { InfoIcon } from '../Icons/Icons'
import CustomerModal from '../MyHosts/CustomerModal'
import Toaster from '../Toaster'

interface Props {
  teamID: string
  host: HostData
  upgradeRequired: boolean
  editCustomer: EditCustomerFunction
  systemInfo?: SystemInfoData
  account?: AccountDetails
}

export const AccountTab: FunctionComponent<Props> = props => {
  const demoMode = useTypedSelector(isDemo)
  const [showEditCustomer, setShowEditCustomer] = useState(false)

  const [applyLicenseCheck, setApplyLicenseCheck] = useState('Checking requirements')
  const [applyLicenseModalOpen, setApplyLicenseModalOpen] = useState(false)
  const applyLicenseAvailable = demoMode || applyLicenseCheck === ''
  const canUploadLicenses = AuthRole.hasPermission('uploadLicense')

  const newVersionRequiredProperty = (propertyValue: any): ReactNode => {
    if (propertyValue) {
      return propertyValue
    }

    return props.upgradeRequired ? <em>Requires version 19.1+</em> : NOT_AVAILABLE
  }

  const getLicensedReleaseStationCount = (count?: number): ReactNode => {
    if (count === -1) {
      return 'Unlimited'
    }

    return newVersionRequiredProperty(count)
  }

  let msClass = ''
  if (props.account) {
    switch (props.account.maintenanceAndSupportStatus) {
      case 'Expiring':
        msClass = 'maintenance-support-warning'
        break
      case 'Expired':
        msClass = 'maintenance-support-error'
        break
    }
  }

  const dispatch = useDispatch()

  const getAccountDetails = useCallback(
    (teamID: string, hostID: string, signal: AbortSignal) => {
      getHostAccountDetails(teamID, hostID, signal)
        .then(accountDetails => {
          dispatch(updateAccountDetails(hostID, accountDetails))
        })
        .catch(_ => {
          if (!signal.aborted) Toaster.notifyFailure('Unable to load account details.')
        })
    },
    [dispatch]
  )

  useEffect(() => {
    if (canUploadLicenses && !demoMode) {
      checkApplyLicense(props.teamID, props.host.id).then(result => setApplyLicenseCheck(result))
    }

    const controller = new AbortController()
    getAccountDetails(props.teamID, props.host.id, controller.signal)
    const hostEventTimer = setInterval(() => getAccountDetails(props.teamID, props.host.id, controller.signal), 60 * 1000)
    return () => {
      controller.abort()
      clearInterval(hostEventTimer)
    }
  }, [demoMode, canUploadLicenses, props.host.id, props.teamID, getAccountDetails])

  return (
    <div id='tab-account' className='tab visible'>
      <header className='flex flex-ver'>
        <h2>
          Account <small className='blk'>Last updated {moment(props.account?.lastUpdate).fromNow()}</small>
        </h2>

        <div className='btn-group'>
          {canUploadLicenses && (
            <>
              <span className='has-tip'>
                <button className={`btn ${applyLicenseAvailable ? '' : 'disabled'}`} onClick={() => setApplyLicenseModalOpen(true)}>
                  Apply license
                </button>

                {!applyLicenseAvailable && <span className='tip b'>{applyLicenseCheck}</span>}
              </span>
            </>
          )}

          {AuthRole.hasPermission('manageCustomer') && (
            <button className='btn secondary edit-customer' onClick={() => setShowEditCustomer(true)}>
              Edit profile/tags
            </button>
          )}
        </div>

        {showEditCustomer && (
          <CustomerModal
            key={props.host.id}
            teamID={props.teamID}
            action={props.editCustomer}
            close={() => setShowEditCustomer(false)}
            isVisible={showEditCustomer}
            host={props.host}
          />
        )}

        {applyLicenseModalOpen && <ApplyLicenseModal teamID={props.teamID} hostID={props.host.id} onClose={() => setApplyLicenseModalOpen(false)} />}
      </header>

      <div className='content flex'>
        <div className='row flex'>
          <div className='col half'>
            <div className='card btm'>
              <header>
                <h4>Customer</h4>
              </header>
              <dl className='zebra'>
                <dt>Tags</dt>
                <dd>
                  {props.host.tags?.map(tag => (
                    <div key={tag} className='tag' data-heap-redact-text>
                      {tag}
                    </div>
                  ))}
                </dd>
                <dt>
                  CRN
                  <i className='icon-info'>
                    <InfoIcon />
                    <span className='tip'>
                      <span className='blk'>Customer Reference Number</span>
                    </span>
                  </i>
                </dt>
                <dd>{newVersionRequiredProperty(props.account?.crn)}</dd>
                {props.account?.maintenanceAndSupportExpiryDate && (
                  <>
                    <dt>Maintenance &amp; support</dt>
                    <dd>
                      <span>
                        <Moment format='D MMM YYYY' date={props.account?.maintenanceAndSupportExpiryDate} />
                        <br />
                        <span className={msClass}>{props.account?.maintenanceAndSupportStatus}</span>
                      </span>
                    </dd>
                  </>
                )}
                {props.account?.licenseExpiryDate && (
                  <>
                    <dt>License expiry</dt>
                    <dd>
                      <Moment format='D MMM YYYY' date={props.account?.licenseExpiryDate} />
                    </dd>
                  </>
                )}
                {!props.account?.maintenanceAndSupportExpiryDate && !props.account?.licenseExpiryDate && (
                  <>
                    <dt>Maintenance &amp; support</dt>
                    <dd>{props.upgradeRequired ? <em>Requires version 19.1+</em> : NOT_AVAILABLE}</dd>
                  </>
                )}
                <dt>License type</dt>
                <dd>{newVersionRequiredProperty(props.account?.licenseType)}</dd>
                <dt>Product</dt>
                <dd>PaperCut {props.account?.paperCutEdition.toUpperCase()}</dd>
                <dt>Version</dt>
                <dd>{props.account?.paperCutVersion}</dd>
                <dt>Hosting environment</dt>
                <dd data-heap-redact-text>{HostDataUtil.getInfrastructureShortName(props.host.infrastructure)}</dd>
                <dt>Operating system</dt>
                <dd>{props.systemInfo?.osPlatform && <>{props.systemInfo?.osPlatform}</>}</dd>
              </dl>
            </div>
            <div className='card'>
              <header>
                <h4>License</h4>
              </header>
              <dl className='zebra' style={{ width: ' 100%' }}>
                <dt>Registered users</dt>
                <dd>{newVersionRequiredProperty(props.account?.registeredUsers)}</dd>
                {AccountDataUtil.getLicensedDeviceCategoryTypeAndCounts('Licensed MFDs', props.account?.licensedMFDs, props.account)}
                {AccountDataUtil.getLicensedDeviceCategoryTypeAndCounts('Licensed SFPs', props.account?.licensedSFPs, props.account)}
                {AccountDataUtil.getLicensedDeviceCategoryTypeAndCounts('Connectors', props.account?.licensedConnectors, props.account)}
                <dt>Release stations</dt>
                <dd>{getLicensedReleaseStationCount(props.account?.licensedReleaseStations)}</dd>
                <dt>Grows trees</dt>
                <dd>{props.account?.growsTreesPlanted || NOT_AVAILABLE}</dd>
              </dl>
            </div>
          </div>

          <div className='col half'>
            <div className='license-certificate'>
              <div className='row flex'>
                <div className='col license-pc center'>
                  <i className={props.host.product === 'mf' ? 'logo-mf' : 'logo-ng'}>{props.host.product}</i>
                </div>
              </div>
              <div className='fp license-customer center'>
                <h6>Licensed to</h6>
                <div className='val' data-heap-redact-text>
                  {props.account?.organisation}
                </div>
                {props.account?.maintenanceAndSupportExpiryDate && (
                  <small className='blk'>
                    M&amp;S valid until <Moment format='D MMM YYYY' date={props.account.maintenanceAndSupportExpiryDate} />
                  </small>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
