import React, { FunctionComponent, useState } from 'react'
import axios from 'axios'
import { DataCenter, GetDataCenterRegionString, getDataCenter } from '../../env'
import { Features } from '../../features'
import { useMountEffect } from '../../hooks/MountEffect'
import { EmailValidator } from '../../service/EmailValidator'
import FeatureToggle from '../../toggle'
import { ExclaimIcon } from '../Icons/Icons'
import CustomModal from '../MyModal/CustomModal'
import Toaster from '../Toaster'

interface Props {
  teamID: string
  onClose: () => void
}

interface Partner {
  teamID: string
  name: string
}

const ServiceProviderInviteModal: FunctionComponent<Props> = props => {
  const [serviceProviderOrgName, setServiceProviderOrgName] = useState('')
  const [teamName, setTeamName] = useState('')
  const [serviceProviderCode, setServiceProviderCode] = useState('')
  const [adminFirstName, setAdminFirstName] = useState('')
  const [adminLastName, setAdminLastName] = useState('')
  const [adminEmailAddress, setAdminEmailAddress] = useState('')
  const [requesterEmailAddress, setRequesterEmailAddress] = useState('')
  const [parentTeamID, setParentTeamID] = useState('')
  const [errMessages, setErrMessages] = useState<string[]>([])
  const [sending, setSending] = useState(false)
  const [skipServiceProviderValidationEnabled, setSkipServiceProviderValidationEnabled] = useState(false)
  const [confirmInvalidCode, setConfirmInvalidCode] = useState(false)
  const [showConfirmInvalidCode, setShowConfirmInvalidCode] = useState(false)

  // This code snippet to determine the partner list will be removed when the real feature is implemented
  const getPartnerList = () => {
    switch (getDataCenter()) {
      case DataCenter.US:
        return [
          { teamID: '5dce12fc68fa390ed253e73c', name: 'ACDI' },
          { teamID: '5ea28044b810550d556451c6', name: 'Cosy' },
          { teamID: '5e8658b9a8cba90ca355b891', name: 'ecoprintQ' },
          { teamID: '5f1d4c12a8f6da001483c51b', name: 'ecoprintQ Argentina' },
          { teamID: 'd93fvly2z782lu6dgqmf0efr', name: 'ecoprintQ Brasil' },
          { teamID: '62849df9ac25b60069389477', name: 'HP3S' },
          { teamID: '6285ba117ada6d0069d02bba', name: 'HP Inc' },
          { teamID: '5eba26e6af500b1a2b854094', name: 'Innotive' },
          { teamID: '5eba25ada151e01a0ceec5ad', name: 'Software Island (Taiwan) Limited' },
          { teamID: '62b272cd0f53a63a893178c3', name: 'Tofu Technologies Pte Ltd' },
          { teamID: '5e45315c7358b50e872ef033', name: 'Toshiba Americas Business Solutions, Inc' },
        ]
      case DataCenter.AU:
        return [
          { teamID: 'v0452zu9qvvwjrm8lt07aaos', name: 'Apposite Solution Limited (China)' },
          { teamID: 'qbis96nlum27k0pt6plyhjng', name: 'Apposite Solution Limited (Hong Kong)' },
          { teamID: 'awuitl5wie24qooq5cupr6ad', name: 'Fujifilm Business Innovation (Hong Kong)' },
          { teamID: 'wtc3rzvvv181yndhketcgfzh', name: 'HP3S' },
          { teamID: 'fyl6sm40fapjg1iy2xmmy47f', name: 'HP Inc' },
          { teamID: '5e7c262ce6e2af0cdf2d1818', name: 'Taco' },
        ]
      case DataCenter.UK:
        return [
          { teamID: '5f323199fdd033003dd3bbea', name: 'iTS UK' },
          { teamID: 'dqkeiyl35ulov3wydl9ew653', name: 'HP3S' },
          { teamID: '13z0svfkokzg2o48ezu46idw', name: 'HP Inc' },
          { teamID: '5de7145fead1f40ee5955595', name: 'Selectec UK' },
        ]
      case DataCenter.EU:
        return [
          { teamID: '5e4be813cae2a80e7ed93d6e', name: 'Bluemega' },
          { teamID: '5f156135bd2b0b0014da559b', name: 'BridgeSol' },
          { teamID: '5e4be7c7446b590e50673b36', name: 'ccTEC' },
          { teamID: '5e4be702078e560e6f858cbe', name: 'Dimatek' },
          { teamID: '5e4be850c3a3ce0e8173f146', name: 'Glocal Value' },
          { teamID: '62747cc7ec232100689e1bd3', name: 'HP3S' },
          { teamID: '6285cc6fe51287006997084e', name: 'HP Inc' },
          { teamID: '5f322e4cb20d800038e696aa', name: 'iTS Benelux' },
          { teamID: '612c203cbcb8a7006b04908a', name: 'iTS DACH' },
          { teamID: '5f3230926cdfa70037b132ff', name: 'iTS France' },
          { teamID: '5f32305a88efbb0037dbb69b', name: 'iTS Nordics' },
          { teamID: '5f3230d10e37b50038b08ca9', name: 'iTS Poland' },
          { teamID: '5f3230fbb20d800038e6c456', name: 'iTS Russia' },
          { teamID: '5f32311f6cdfa70037b13c51', name: 'iTS Spain' },
          { teamID: '5f3231503be76a00378aa90f', name: 'iTS Turkey' },
          { teamID: '5f3232043be76a00378ab4a6', name: 'iTS UK (EU Data Center)' },
          { teamID: '5e4be76ced89390e8e31fa94', name: 'Jet Services' },
          { teamID: '5e8ecf1cd551690cb451dea7', name: 'Plankomat' },
          { teamID: '5e4be671ed89390e8e31fa62', name: 'Selectec Nordics' },
          { teamID: '5f32484e6cdfa70037b29dcb', name: 'Selectec UK (EU Data Center)' },
          { teamID: '5ebe708480b6a60cb1723ab3', name: 'Symbiosys' },
        ]
      case DataCenter.Staging:
        return [
          { teamID: '5e291b0019dd7e0e9b1cd887', name: 'Tinh ASC' },
          { teamID: '5e45e9028f7d390e597af984', name: 'Brendan ASC 1' },
          { teamID: '5e2a73e3f4499d0e91660031', name: 'Brendan ASC 2' },
        ]
      case DataCenter.Test:
        return [
          { teamID: '5d4cf5440614f50e85c0f97a', name: 'Tinh ASC' },
          { teamID: '5d883bfb9b19640c4ae562e0', name: 'Jeremy ASC' },
        ]
    }
  }

  const partnerList: Partner[] = getPartnerList()
  let serviceProviderUUID = ''

  useMountEffect(() => {
    FeatureToggle.isOn(props.teamID, Features.SkipServiceProviderValidation).then(enabled => {
      setSkipServiceProviderValidationEnabled(enabled)
    })
  })

  const validate = async () => {
    let errs: string[] = []
    if (!serviceProviderOrgName) {
      errs.push('Service provider organization name is required.')
    }

    if (serviceProviderCode) {
      await validateServiceProviderCode(serviceProviderCode)
        .then(resp => {
          serviceProviderUUID = resp.data.resellerUUID
        })
        .catch(() => {
          if (!skipServiceProviderValidationEnabled) {
            serviceProviderUUID = ''
            errs.push('Service provider code is invalid.')
          } else if (!confirmInvalidCode) {
            setShowConfirmInvalidCode(true)
            errs.push('Service provider code is invalid. Fix or confirm the invalid code to continue.')
          }
        })
    } else {
      errs.push('Service provider code is required.')
    }

    if (!requesterEmailAddress) {
      errs.push('Requester email address is required.')
    } else if (!EmailValidator.validate(requesterEmailAddress.trim())) {
      errs.push('Requester email address is invalid.')
    }

    if (!adminEmailAddress) {
      errs.push('Service provider admin email address is required.')
    } else if (!EmailValidator.validate(adminEmailAddress.trim())) {
      errs.push('Service provider admin email address is invalid.')
    }

    return errs
  }

  const validateServiceProviderCode = (code: string) => {
    return axios.get<any>('/api/teams/' + props.teamID + '/service-provider-code/' + code + '/validate')
  }

  const inviteServiceProvider = (invite: {
    resellerName: string
    name: string
    code: string
    uuid: string
    adminFirstName: string
    adminLastName: string
    adminEmail: string
    requesterEmail: string
    parentTeamID: string
  }) => {
    return axios.post('/api/teams/' + props.teamID + '/service-providers', invite)
  }

  const onCancelBtnClicked = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault()
    props.onClose()
  }

  const onSendEmailBtnClicked = async (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault()
    const errs = await validate()
    setErrMessages(errs)
    if (errs && errs.length > 0) {
      return
    }
    setSending(true)
    inviteServiceProvider({
      resellerName: serviceProviderOrgName,
      name: teamName,
      code: serviceProviderCode,
      uuid: serviceProviderUUID,
      adminFirstName: adminFirstName,
      adminLastName: adminLastName,
      adminEmail: adminEmailAddress.trim(),
      requesterEmail: requesterEmailAddress.trim(),
      parentTeamID: parentTeamID,
    })
      .then(() => {
        Toaster.notifySuccess(serviceProviderOrgName + ' invited.')
        setServiceProviderOrgName('')
        setTeamName('')
        setServiceProviderCode('')
        setAdminFirstName('')
        setAdminLastName('')
        setAdminEmailAddress('')
        setRequesterEmailAddress('')
        setParentTeamID('')
        setErrMessages([])
        setShowConfirmInvalidCode(false)
        setConfirmInvalidCode(false)
      })
      .catch(() => Toaster.notifyFailure('Unable to invite ' + serviceProviderOrgName + '.'))
      .finally(() => {
        setSending(false)
      })
  }

  return (
    <CustomModal id='popup-serviceprovider' close={props.onClose} width='640px'>
      <header className='center'>
        <h3>Invite service provider</h3>

        <p>
          Complete this form to create a service provider account in the <strong>{GetDataCenterRegionString()}</strong> data center.
        </p>
      </header>

      {errMessages.length > 0 && (
        <div className='alert error'>
          <i className='icon-exclaim'>
            <ExclaimIcon />
          </i>
          <div className='message'>
            <ul>
              {errMessages.map(msg => (
                <li key={msg}>{msg}</li>
              ))}
            </ul>
          </div>
        </div>
      )}

      <div className='content'>
        <div className='field'>
          <label id='requester-email-label'>Requester email address</label>
          <input
            type='text'
            placeholder='requester@example.com'
            value={requesterEmailAddress}
            onChange={e => setRequesterEmailAddress(e.target.value)}
            aria-labelledby='requester-email-label'
          />
        </div>

        <div className='field'>
          <label id='partner-label'>Partner</label>
          <div className='select'>
            <select onChange={e => setParentTeamID(e.target.value)} value={parentTeamID} aria-labelledby='partner-label'>
              <option key='no-partner' value=''>
                No partner
              </option>
              {partnerList
                .sort((a, b) => a.name.localeCompare(b.name))
                .map(partner => (
                  <option key={partner.teamID} value={partner.teamID}>
                    {partner.name}
                  </option>
                ))}
            </select>
          </div>
        </div>

        <div className='field'>
          <label id='service-provider-code-label'>Service provider code</label>
          <input
            type='text'
            placeholder='SERVICE-PROVIDER-CODE'
            value={serviceProviderCode}
            onChange={e => setServiceProviderCode(e.target.value)}
            aria-labelledby='service-provider-code-label'
          />
        </div>

        <div className='field'>
          <label id='org-name-label'>Service provider organization name</label>
          <input type='text' value={serviceProviderOrgName} onChange={e => setServiceProviderOrgName(e.target.value)} aria-labelledby='org-name-label' />
        </div>

        <div className='field'>
          <label id='team-name-label'>Team name</label>
          <input type='text' value={teamName} onChange={e => setTeamName(e.target.value)} aria-labelledby='team-name-label' />
        </div>

        <div className='field'>
          <label id='admin-email-label'>Admin email address</label>
          <input
            type='text'
            placeholder='service-provider-admin@example.net'
            value={adminEmailAddress}
            onChange={e => setAdminEmailAddress(e.target.value)}
            aria-labelledby='admin-email-label'
          />
        </div>

        {showConfirmInvalidCode && (
          <div className='field inl'>
            <label id='confirm-invalid-code-label'>
              <input type='checkbox' onChange={() => setConfirmInvalidCode(!confirmInvalidCode)} />
              Yes, I want to proceed with an invalid code.
            </label>
          </div>
        )}
      </div>

      <footer>
        <button id='cancel' className='btn outline' onClick={onCancelBtnClicked}>
          Cancel
        </button>

        <button id='send' className={`btn ${sending ? 'disabled' : ''}`} disabled={sending} onClick={onSendEmailBtnClicked}>
          Send email
        </button>
      </footer>
    </CustomModal>
  )
}

export default ServiceProviderInviteModal
