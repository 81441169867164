import React, { FunctionComponent, useEffect, useState } from 'react'
import axios from 'axios'
import { Features } from '../../features'
import { EmailValidator } from '../../service/EmailValidator'
import FeatureToggle from '../../toggle'
import { ExclaimIcon } from '../Icons/Icons'
import CustomModal from '../MyModal/CustomModal'
import Toaster from '../Toaster'

interface Props {
  teamID: string
  onClose: () => void
}

export interface PartnerData {
  teamID: string
  name: string
}

const ServiceProviderInviteModal: FunctionComponent<Props> = props => {
  const [serviceProviderOrgName, setServiceProviderOrgName] = useState('')
  const [teamName, setTeamName] = useState('')
  const [serviceProviderCode, setServiceProviderCode] = useState('')
  const [adminFirstName, setAdminFirstName] = useState('')
  const [adminLastName, setAdminLastName] = useState('')
  const [adminEmailAddress, setAdminEmailAddress] = useState('')
  const [errMessages, setErrMessages] = useState<string[]>([])
  const [sending, setSending] = useState(false)
  const [skipServiceProviderValidationEnabled, setSkipServiceProviderValidationEnabled] = useState(false)
  const [confirmInvalidCode, setConfirmInvalidCode] = useState(false)
  const [showConfirmInvalidCode, setShowConfirmInvalidCode] = useState(false)

  let serviceProviderUUID = ''

  useEffect(() => {
    FeatureToggle.isOn(props.teamID, Features.SkipServiceProviderValidation).then(enabled => setSkipServiceProviderValidationEnabled(enabled))
  }, [props.teamID])

  const validate = async () => {
    let errs: string[] = []
    if (!serviceProviderOrgName) {
      errs.push('Service provider organization name is required.')
    }

    if (serviceProviderCode) {
      await validateServiceProviderCode(serviceProviderCode)
        .then(resp => {
          serviceProviderUUID = resp.data.resellerUUID
        })
        .catch(() => {
          if (!skipServiceProviderValidationEnabled) {
            serviceProviderUUID = ''
            errs.push('Service provider code is invalid.')
          } else if (!confirmInvalidCode) {
            setShowConfirmInvalidCode(true)
            errs.push('Service provider code is invalid. Fix or confirm the invalid code to continue.')
          }
        })
    } else {
      errs.push('Service provider code is required.')
    }

    if (!adminFirstName) {
      errs.push('Service provider admin first name is required.')
    }

    if (!adminLastName) {
      errs.push('Service provider admin last name is required.')
    }

    if (!adminEmailAddress) {
      errs.push('Service provider admin email address is required.')
    }

    if (adminEmailAddress && !EmailValidator.validate(adminEmailAddress.trim())) {
      errs.push('Email address is invalid.')
    }

    return errs
  }

  const validateServiceProviderCode = (code: string) => {
    return axios.get<any>('/api/teams/' + props.teamID + '/service-provider-code/' + code + '/validate')
  }

  const inviteServiceProvider = (invite: {
    resellerName: string
    name: string
    code: string
    uuid: string
    adminFirstName: string
    adminLastName: string
    adminEmail: string
    parentTeamID: string
  }) => {
    return axios.post('/api/teams/' + props.teamID + '/service-providers', invite)
  }

  const onCancelBtnClicked = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault()
    props.onClose()
  }

  const onSendEmailBtnClicked = async (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault()
    const errs = await validate()
    setErrMessages(errs)
    if (errs && errs.length > 0) {
      return
    }
    setSending(true)
    inviteServiceProvider({
      resellerName: serviceProviderOrgName,
      name: teamName,
      code: serviceProviderCode,
      uuid: serviceProviderUUID,
      adminFirstName: adminFirstName,
      adminLastName: adminLastName,
      adminEmail: adminEmailAddress.trim(),
      parentTeamID: props.teamID,
    })
      .then(() => {
        Toaster.notifySuccess(serviceProviderOrgName + ' invited.')
        setServiceProviderOrgName('')
        setTeamName('')
        setServiceProviderCode('')
        setAdminFirstName('')
        setAdminLastName('')
        setAdminEmailAddress('')
        setErrMessages([])
        setShowConfirmInvalidCode(false)
        setConfirmInvalidCode(false)
      })
      .catch(() => Toaster.notifyFailure('Unable to invite ' + serviceProviderOrgName + '.'))
      .finally(() => {
        setSending(false)
      })
  }

  return (
    <CustomModal id='popup-serviceprovider' close={props.onClose} width='640px'>
      <header className='center'>
        <h3>Invite service provider</h3>

        <p>Add service providers to give them access to PaperCut Multiverse. We’ll send them a welcome email with setup instructions.</p>
      </header>

      {errMessages.length > 0 && (
        <div className='alert error'>
          <i className='icon-exclaim'>
            <ExclaimIcon />
          </i>
          <div className='message'>
            <ul>
              {errMessages.map(msg => (
                <li key={msg}>{msg}</li>
              ))}
            </ul>
          </div>
        </div>
      )}

      <div className='content'>
        <div className='field'>
          <label id='org-name-label'>Service provider organization name</label>
          <input type='text' value={serviceProviderOrgName} onChange={e => setServiceProviderOrgName(e.target.value)} aria-labelledby='org-name-label' />
        </div>

        <div className='field'>
          <label id='team-name-label'>Team name</label>
          <input type='text' value={teamName} onChange={e => setTeamName(e.target.value)} aria-labelledby='team-name-label' />
        </div>

        <div className='field'>
          <label id='service-provider-code-label'>Service provider code</label>
          <input
            type='text'
            placeholder='SERVICE-PROVIDER-CODE'
            value={serviceProviderCode}
            onChange={e => setServiceProviderCode(e.target.value)}
            aria-labelledby='service-provider-code-label'
          />
        </div>

        <div className='field'>
          <label id='admin-first-name-label'>Admin first name</label>
          <input type='text' placeholder='John' value={adminFirstName} onChange={e => setAdminFirstName(e.target.value)} aria-labelledby='admin-first-name-label' />
        </div>

        <div className='field'>
          <label id='admin-last-name-label'>Admin last name</label>
          <input type='text' placeholder='Smith' value={adminLastName} onChange={e => setAdminLastName(e.target.value)} aria-labelledby='admin-last-name-label' />
        </div>

        <div className='field'>
          <label id='admin-email-label'>Admin email address</label>
          <input
            type='text'
            placeholder='john.smith@example.com'
            value={adminEmailAddress}
            onChange={e => setAdminEmailAddress(e.target.value)}
            aria-labelledby='admin-email-label'
          />
        </div>

        {showConfirmInvalidCode && (
          <div className='field inl'>
            <label id='confirm-invalid-code-label'>
              <input type='checkbox' onChange={() => setConfirmInvalidCode(!confirmInvalidCode)} />
              Yes, I want to proceed with an invalid code.
            </label>
          </div>
        )}
      </div>

      <footer>
        <button className='btn outline' onClick={onCancelBtnClicked}>
          Cancel
        </button>
        <button className={`btn ${sending ? 'disabled' : ''}`} disabled={sending} onClick={onSendEmailBtnClicked}>
          Send invitation
        </button>
      </footer>
    </CustomModal>
  )
}

export default ServiceProviderInviteModal
