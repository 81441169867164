import React, { FunctionComponent, useState } from 'react'
import AuthRole from '../../role'
import { AccountDetails } from '../Account/Data'
import { PrinterIcon } from '../Icons/Icons'
import CustomerModal from '../MyHosts/CustomerModal'
import { EditCustomerFunction, HostData, HostDataUtil, SystemInfoData } from './Data'
import styles from './SystemInfo.module.css'

interface Props {
  data: SystemInfoData
  host: HostData
  teamID: string
  editCustomer: EditCustomerFunction
  account?: AccountDetails
}

const SystemInfo: FunctionComponent<Props> = (props: Props) => {
  const [showEditCustomer, setShowEditCustomer] = useState(false)

  let clazz: string = ''
  let hasData: boolean = false

  if (props.data.version) {
    hasData = true
  } else {
    clazz = styles.systemInfo
  }

  const productName = (product: string, sysInfo: SystemInfoData, accDetails?: AccountDetails) => {
    let name: string = 'PaperCut version'

    switch (product) {
      case 'mf':
        name = 'PaperCut MF'
        break
      case 'ng':
        name = 'PaperCut NG'
        break
    }

    // if we have a version and no edition assume it's PaperCut MF as default
    const ver = HostDataUtil.getVersion(sysInfo, accDetails)
    if (ver) {
      name = (name ? name : 'PaperCut MF') + ' ' + ver
    }

    return name
  }

  const addTagClicked = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault()
    setShowEditCustomer(true)
  }

  return (
    <dl className={`icons ${clazz}`}>
      <dt>Tags</dt>

      <dd className='known'>
        {props.host.tags && props.host.tags.length > 0 && (
          <>
            {props.host.tags?.map(tag => (
              <div key={tag} className='tag' data-heap-redact-text>
                {tag}
              </div>
            ))}

            {AuthRole.hasPermission('manageCustomer') && (
              <a title={'Add tags'} className='btn add outline x-sml' onClick={addTagClicked}>
                ＋
              </a>
            )}
          </>
        )}

        {(!props.host.tags || props.host.tags.length === 0) && AuthRole.hasPermission('manageCustomer') && (
          <a title={'Add tags'} className='btn add outline x-sml' onClick={addTagClicked}>
            Add tag
          </a>
        )}
      </dd>

      {showEditCustomer && (
        <CustomerModal
          key={props.host.id}
          teamID={props.teamID}
          action={props.editCustomer}
          close={() => setShowEditCustomer(false)}
          isVisible={showEditCustomer}
          host={props.host}
          addTag={true}
        />
      )}

      <dt>PaperCut</dt>

      <dd className='known'>{productName(props.data.product ? props.data.product : props.host.product, props.data, props.account)}</dd>

      <dt>OS</dt>

      <dd>{props.data.osPlatform ? props.data.osPlatform.replace('()', '') : 'OS platform'}</dd>

      <dt>Embeddeds</dt>

      {hasData && (
        <dd title='Total number of PaperCut embeddeds'>
          <i className='icon twelve'>
            <PrinterIcon />
          </i>

          {props.data.embeddedCount}
        </dd>
      )}

      {!hasData && <dd>Number of embeddeds</dd>}

      <dt>Hosting</dt>

      <dd className='known' data-heap-redact-text>
        {HostDataUtil.getInfrastructureText(props.host.infrastructure)}
      </dd>
    </dl>
  )
}

export default SystemInfo
