export enum DataCenter {
  Test = 'test',
  Staging = 'staging',
  AU = 'au',
  EU = 'eu',
  UK = 'uk',
  US = 'us',
}

export const getDataCenter = () => {
  switch (window.location.hostname) {
    default:
      return DataCenter.US
    case 'multiverse.papercut.software':
    case '127.0.0.1.nip.io':
      return DataCenter.Test
    case 'staging.multiverse.papercut.com':
      return DataCenter.Staging
    case 'au.multiverse.papercut.com':
      return DataCenter.AU
    case 'eu.multiverse.papercut.com':
      return DataCenter.EU
    case 'uk.multiverse.papercut.com':
      return DataCenter.UK
  }
}

export interface AppConfig {
  auth0Config: Auth0Config
}

export interface Auth0Config {
  Auth0ClientID: string
  Auth0Domain: string
  Auth0Audience: string
}

const testAppConf = {
  auth0Config: {
    Auth0ClientID: 'lcU0R7Vs14WJbUAv8167EIklEP6GZmLn',
    Auth0Domain: 'dev-multiverse-dash.au.auth0.com',
    Auth0Audience: 'https://api.dashboard.multiverse.papercut.com',
  },
} as AppConfig

const stagingAppConf = {
  auth0Config: {
    Auth0ClientID: 'fbCquyzckghTTDle7RDlYpOhSS3NsHsm',
    Auth0Domain: 'staging-multiverse-dash.auth0.com',
    Auth0Audience: 'https://staging.multiverse.papercut.com',
  },
} as AppConfig

const prodUSAppConf = {
  auth0Config: {
    Auth0ClientID: 'gvJ0ZFkajkXwV14vBn2CMD75JbQzGa84',
    Auth0Domain: 'login.multiverse.papercut.com',
    Auth0Audience: 'Dashboard Backend US',
  },
} as AppConfig

const prodEUAppConf = {
  auth0Config: {
    Auth0ClientID: 'RYERvKcRiYtpsVd5bAEet372qWLGqZdu',
    Auth0Domain: 'login.eu.multiverse.papercut.com',
    Auth0Audience: 'Dashboard Backend US',
  },
} as AppConfig

// UK uses the same Auth0 tenant as EU
const prodUKAppConf = {
  auth0Config: {
    Auth0ClientID: 'RYERvKcRiYtpsVd5bAEet372qWLGqZdu',
    Auth0Domain: 'login.eu.multiverse.papercut.com',
    Auth0Audience: 'Dashboard Backend US',
  },
} as AppConfig

// AU uses the same Auth0 tenant as US
const prodAUAppConf = {
  auth0Config: {
    Auth0ClientID: 'gvJ0ZFkajkXwV14vBn2CMD75JbQzGa84',
    Auth0Domain: 'login.multiverse.papercut.com',
    Auth0Audience: 'Dashboard Backend US',
  },
} as AppConfig

export function GetAppConfig(): AppConfig {
  switch (getDataCenter()) {
    default:
      return prodUSAppConf
    case DataCenter.Test:
      return testAppConf
    case DataCenter.Staging:
      return stagingAppConf
    case DataCenter.AU:
      return prodAUAppConf
    case DataCenter.EU:
      return prodEUAppConf
    case DataCenter.UK:
      return prodUKAppConf
  }
}

export function GetDataCenterRegionString(): string {
  return GetDataCenterDisplayName(getDataCenter())
}

export function GetDataCenterDisplayName(dataCenter: string): string {
  switch (dataCenter) {
    default:
      return 'US'
    case DataCenter.Test:
      return 'Test'
    case DataCenter.Staging:
      return 'Staging'
    case DataCenter.AU:
      return 'AU'
    case DataCenter.EU:
      return 'EU'
    case DataCenter.UK:
      return 'UK'
  }
}
