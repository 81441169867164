import React, { FunctionComponent, useState } from 'react'
import axios from 'axios'
import { useMountEffect } from '../../hooks/MountEffect'
import { useTypedSelector } from '../../redux/reducers'
import { isDemo } from '../../redux/selectors'
import AuthRole from '../../role'
import { EditCustomerFunction, HostData } from '../Host/Data'
import { LoadingDots } from '../LoadingDots/LoadingDots'
import CustomerModal from '../MyHosts/CustomerModal'
import { AccountProfile, dcUsGenesis, getPmitcDcDisplayName } from '../Pmitc/Data'
import Toaster from '../Toaster'

interface Props {
  teamID: string
  host: HostData
  editCustomer: EditCustomerFunction
}

const loadProfile = async (teamID: string, hostID: string): Promise<AccountProfile> => {
  return axios
    .get('/api/teams/' + teamID + '/customers/' + hostID + '/pmitc/account')
    .then(response => {
      return Promise.resolve(response.data)
    })
    .catch(err => {
      console.log('failed to load account profile: ' + err)
      Toaster.notifyFailure('Unable to load profile.')
      return Promise.reject(err)
    })
}

export const HiveAccountTab: FunctionComponent<Props> = props => {
  const demoMode = useTypedSelector(isDemo)
  const [showEditCustomer, setShowEditCustomer] = useState(false)
  const [profile, setProfile] = useState<AccountProfile>()
  const [loading, setLoading] = useState(true)

  useMountEffect(() => {
    if (demoMode) {
      setProfile({ dataCenter: dcUsGenesis })
      setLoading(false)
      return
    }

    loadProfile(props.teamID, props.host.id)
      .then(p => setProfile(p))
      .finally(() => setLoading(false))
  })

  return (
    <div id='tab-account' className='tab visible'>
      <header className='flex flex-ver'>
        <h2>Account</h2>
        <div className='btn-group'>
          {AuthRole.hasPermission('manageCustomer') && (
            <a className='btn secondary edit-customer' onClick={() => setShowEditCustomer(true)}>
              Edit profile/tags
            </a>
          )}
        </div>

        {showEditCustomer && (
          <CustomerModal
            key={props.host.id}
            teamID={props.teamID}
            action={props.editCustomer}
            close={() => setShowEditCustomer(false)}
            isVisible={showEditCustomer}
            host={props.host}
          />
        )}
      </header>

      <div className='content flex'>
        <div className='row flex'>
          <div className='col half'>
            <div className='card btm'>
              <header>
                <h4>Profile</h4>
              </header>
              <dl className='zebra' style={{ width: '100%' }}>
                <dt>Organization</dt>
                <dd data-heap-redact-text>{props.host.name}</dd>
                <dt>Tags</dt>
                <dd>
                  {props.host.tags?.map(tag => (
                    <div key={tag} className='tag' data-heap-redact-text>
                      {tag}
                    </div>
                  ))}
                </dd>
                <dt>Data center</dt>
                {loading && (
                  <dd>
                    <div className='loading'>
                      <LoadingDots />
                    </div>
                  </dd>
                )}
                {!loading && <dd>{profile ? getPmitcDcDisplayName(profile.dataCenter) : '?'}</dd>}
              </dl>
            </div>
            <div className='card btm coming-soon'>
              <span className='sample'>Need this?</span>
              <header>
                <h4>Subscription</h4>
              </header>
              <dl className='zebra' style={{ width: ' 100%' }}>
                <dt>Organization ID</dt>
                <dd></dd>
                <dt>Product</dt>
                <dd></dd>
                <dt>Subscription</dt>
                <dd></dd>
                <dt>Valid between</dt>
                <dd></dd>
                <dt>Reference number</dt>
                <dd></dd>
                <dt>Subscription type</dt>
                <dd></dd>
              </dl>
            </div>
            <div className='card coming-soon'>
              <span className='sample'>Need this?</span>
              <header>
                <h4>Entitlements</h4>
              </header>
              <dl className='zebra' style={{ width: ' 100%' }}>
                <dt>Active users</dt>
                <dd></dd>
                <dt>Devices with print release only</dt>
                <dd></dd>
                <dt>Devices with Full Embedded app</dt>
                <dd></dd>
                <dt>Devices with Lite Release app</dt>
                <dd></dd>
              </dl>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
