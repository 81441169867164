import React, { FunctionComponent } from 'react'
import RouteTab from '../Tabs/RouteTab'

const TeamNavBar: FunctionComponent = () => {
  return (
    <div className='tab'>
      <header>
        <div className='auto'>
          <h2>My team</h2>

          <div className='tabs'>
            <ul className='auto'>
              <RouteTab path='/my-team'>Members</RouteTab>

              <RouteTab path='/my-team/monitoring'>Monitoring</RouteTab>

              <RouteTab path='/my-team/settings'>Settings</RouteTab>
            </ul>
          </div>
        </div>
      </header>
    </div>
  )
}

export default TeamNavBar
